import { provideHttpClient, withFetch, withInterceptorsFromDi, withJsonpSupport } from '@angular/common/http';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerModule, provideClientHydration } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

//Header
import { HeaderComponent } from './components/header/header.component';
import { VideoHoverDirective } from './components/header/solutions-menu/video-hover.directive';
import { SolutionsMenuComponent } from './components/header/solutions-menu/solutionsmenu.component';
import { AboutMenuComponent } from './components/header/about-menu/aboutmenu.component';
import { MyIntercargoMenuComponent } from './components/header/my-intercargo-menu/myintercargomenu.component';
import { SearchMenuComponent } from './components/header/search-menu/searchmenu.component';
import { SupportComponent } from './pages/support/support.component';
import { CreateEmailComponent } from './shared/components/create-email/create-email.component';
import { GlobeComponent } from './pages/globe/globe.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
//Footer
import { FooterComponent } from './components/footer/footer.component';

//My Intercargo
import { OpenaccountComponent } from './pages/Members-Hub/openaccount/openaccount.component';
import { ChangepasswordComponent } from './pages/Members-Hub/changepassword/changepassword.component';
import { CareersComponent } from './pages/Members-Hub/careers/careers.component';
import { SustainabilityComponent } from './pages/Members-Hub/sustainability/sustainability.component';
import { FaqsComponent } from './pages/Members-Hub/faqs/faqs.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { BlogModule } from './pages/Blogs/blog.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ConsentComponent } from './components/consent/consent.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { TermsAndCondtionsComponent } from './pages/terms-and-condtions/terms-and-condtions.component';
import { MapComponent } from './shared/components/map/map.component';
import { CommonModule } from '@angular/common';
import { ThankyouComponent } from './components/thankyou/thankyou.component';
import { LeftImgSMModule } from './shared/components/leftimage-sm/leftimage-sm.module';
import { SearchComponent } from './pages/search/search.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CreateemailthankyouComponent } from './components/urlmodal/createemailthankyou/createemailthankyou.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ErrorModule } from './components/error/error.module';
import { OrganisationerrorComponent } from './components/urlmodal/organisationerror/organisationerror.component';
import { DownloadpdfThankyouComponent } from './components/urlmodal/downloadpdf-thankyou/downloadpdf-thankyou.component';
import { GeolocationService } from './services/geolocation.service';



function fetchLocale(geoService: GeolocationService) {
    return () => geoService.fetchLocation().then(locale => {
        geoService.locale = locale === 'US' ? 'en-US' : 'en-GB';
    });
}


@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        SolutionsMenuComponent,
        AboutMenuComponent,
        MyIntercargoMenuComponent,
        SearchMenuComponent,
        SupportComponent,
        CreateEmailComponent,
        MapComponent,
        SearchComponent,
        GlobeComponent,
        FaqsComponent,
        CareersComponent,
        SustainabilityComponent,
        OpenaccountComponent,
        ChangepasswordComponent,
        ConsentComponent,
        PrivacyPolicyComponent,
        TermsAndCondtionsComponent,
        MapComponent,
        VideoHoverDirective,
        ThankyouComponent,
        DownloadpdfThankyouComponent,
        CreateemailthankyouComponent,
        OrganisationerrorComponent,



    ],
    bootstrap: [AppComponent],
    imports: [BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatExpansionModule,
        MatSnackBarModule,
        MatCheckboxModule,
        BlogModule,
        LeftImgSMModule,
        NgSelectModule,
        ErrorModule,
        FontAwesomeModule,

    ],
    providers: [
        provideHttpClient(withInterceptorsFromDi(), withJsonpSupport(), withFetch()),
        provideClientHydration(),
        {
            provide: APP_INITIALIZER,
            useFactory: fetchLocale,
            deps: [GeolocationService],
            multi: true
        },
        {
            provide: MAT_DATE_LOCALE,
            useFactory: (geoService: GeolocationService) => geoService.locale,
            deps: [GeolocationService]
        },

    ]
})
export class AppModule { }
