<div class="mega-menu">

    <div class="row d-flex align-items-center">
        <div class="col-sm-9 col-xl-9 col-xxl-9">
            <input style="background-color: var(--input-color) !important" type="text" class="form-control" placeholder="Search" [(ngModel)]="searchedString"  name="SearchInput" (keydown)="handleEnterKey($event)">
        </div>
        <div class="col-sm-2 col-xl-2 col-xxl-2">
            <button class="btn btn-secondary w-100" type="button" (click)="searchBtnClicked()">Search</button>
        </div>
        <div class="col-sm-1 col-xl-1 col-xxl-1">
            <a class="btn" type="button" (click)="closeMenu()"><i class="bi bi-x" style="font-size: xx-large;"></i></a>
        </div>
    </div>

    <div class="row mt-5">
        <div class="col">
            <p>Common Searches</p>
        </div>
    </div>

    <div class="row">
        <div class="col d-flex p-1">
            <!-- <a routerLink="/freight/air" class="mx-2 fs-5">Quick Quotes</a> -->
            <a routerLink="/MyIntercargo/track-it" class="mx-2 fs-5" (click)="closeMenu()">Track Shipment</a>
            <a routerLink="/MyIntercargo/flight-schedules" class="mx-2 fs-5" (click)="closeMenu()">Flight Schedules</a>
            <a routerLink="/freight/air" class="mx-2 fs-5" (click)="closeMenu()">Air Freight</a>
        </div>
    </div>

    <!-- <div  class="row mt-5" *ngIf="searchedString!=''">
        <h1 class="text-primary">Search Results:</h1>
        <ol class="ms-5" >
            <li *ngFor="let item of searchResults"><a class="fs-5 ms-5" [routerLink]="item.url" (click)="closeMenu()" >{{ item.name }}</a></li>
        </ol>
    </div> -->
</div>