<div class="loading-overlay" *ngIf="loader">
  <div class="spinner"></div>
</div>

<div class="bg-light container-main">
  <p class="fw-medium back-button d-flex align-items-center" id="back"
    style="cursor: pointer" routerLink>
    <i class="bi bi-chevron-left fw-bolder h2-icon me-2"></i>
    Back to Intercargo.com
  </p>

  <div class="container-fluid">
    <h1 class="fs-2 text-primary fw-medium my-5" *ngIf="megaMenu">
      New Company Account Registration
    </h1>
    <!-- <h1 class="fs-2 text-primary fw-medium my-5" *ngIf="!megaMenu">Sign Up to
      Conexa<sup>&copy;</sup> </h1> -->
    <div class="d-flex flex-row align-items-center justify-content-center">
      <h1 class="fs-1 text-primary fw-medium my-5" *ngIf="!megaMenu">
        Sign Up to
        <img [src]="conexaLogo" alt="Conexa Logo"
          class="img-fluid pb-1 conexaLogo" />
      </h1>
    </div>
    <p *ngIf="!megaMenu">
      Get ready to streamline your logistics with Conexa: Expertly delivered.
    </p>
    <p *ngIf="megaMenu">
      Unlock a seamless logistics experience with Intercargo: Efficiency
      redefined.
    </p>

    <span>
      Please note this registration is intended for companies and agents only.
    </span>

    <div class="container form-container" *ngIf="!megaMenu">
      <form [formGroup]="registrationForm!" (ngSubmit)="onSubmit()">
        <div class="form-row row">
          <div class="form-group col-md-6">
            <label for="customerID" class="form-label">Organisation Code</label>
            <input style="background-color: var(--input-color) !important"
              type="text"
              class="form-control"
              id="customerID"
              formControlName="customerID"
              [ngClass]="{
              'is-valid': registrationForm!.get('customerID')?.value && validcustomerID === true,
              'is-invalid': registrationForm!.get('customerID')?.value && validcustomerID === false,
              '': validcustomerID === undefined
            }"
              (input)="validcustomerID = undefined"
              placeholder="Enter Company Code eg. INTERICJFK"
              (focusout)="onCustomerIDFocusOut()"
              autocomplete="new-password"
              name="no-autocomplete-customerID"
              readonly
              onfocus="this.removeAttribute('readonly')" />

            <sub class="text-primary" *ngIf="validcustomerID">
              Existing clients can use company code eg. INTERICJFK
            </sub>
            <div id="validationServercustomerID" class="invalid-feedback d-none"
              *ngIf="!validcustomerID">
              Please provide a valid company code.
            </div>
          </div>

          <div class="form-group col-md-3">
            <label for="firstName" class="form-label">First Name</label>
            <input style="background-color: var(--input-color) !important" type="text" class="form-control py-3" id="firstName"
              formControlName="firstName"
              placeholder="Enter First Name" required />
            <div *ngIf="
                registrationForm!.get('firstName')?.invalid &&
                isSubmitted
              " class="text-danger">
              Please enter your first name.
            </div>
          </div>

          <div class="form-group col-md-3">
            <label for="lastName" class="form-label">Last Name</label>
            <input style="background-color: var(--input-color) !important" type="text" class="form-control py-3" id="lastName"
              formControlName="lastName"
              placeholder="Enter Last Name" required />
            <div *ngIf="
                registrationForm!.get('lastName')?.invalid &&
                isSubmitted
              " class="text-danger">
              Please enter your last name.
            </div>
          </div>
        </div>
        <div class="form-row row">
          <div class="form-group col-md-6">
            <label for="email" class="form-label">Email</label>
            <input style="background-color: var(--input-color) !important" type="email" class="form-control py-3" id="email"
              formControlName="email"
              placeholder="Enter your email" required />
            <div *ngIf="
                registrationForm!.get('email')?.invalid &&
                isSubmitted
              " class="text-danger">
              Please enter a valid email.
            </div>
          </div>
          <div class="form-group col-md-6">
            <label for="phone" class="form-label">Contact Number</label>
            <input style="background-color: var(--input-color) !important" type="text" class="form-control py-3" id="phone"
              formControlName="phone" placeholder="+4467456543"
              required />
            <div *ngIf="
                registrationForm!.get('phone')?.invalid &&
                isSubmitted" class="text-danger">
              Please enter your contact number.
            </div>
          </div>
        </div>
        <div class="form-row row">
          <div class="form-group col-md-6">
            <label for="companyName" class="form-label">Company Name</label>
            <input style="background-color: var(--input-color) !important" type="text" class="form-control py-3" id="companyName"
              formControlName="companyName"
              placeholder="Enter Company Name" required [disabled]="true" />
            <div *ngIf="
                registrationForm!.get('companyName')?.invalid &&
                isSubmitted
              " class="text-danger">
              Please enter your company name.
            </div>
          </div>
          <div class="form-group col-md-6">
            <label for="job" class="form-label">Job Title</label>
            <div class="select-editable" class="form-group">
              <ng-select [items]="jobTitles" bindLabel="title" bindValue="title"
                labelForId="job" [multiple]="false"
                [placeholder]="jobPlaceHolder" formControlName="jobTitle"
                (change)="onJobChange($event)"
                [searchable]="true" (focus)="onFocus()" (blur)="onBlur()"
                (search)="onJobSearch($event)">
              </ng-select>
            </div>

            <div *ngIf="
                (registrationForm!.get('job')?.invalid &&
                  isSubmitted) ||
                cityError
              " class="text-danger">
              Please select your job title
            </div>
          </div>

        </div>

        <div class="form-row row">
          <div class="form-group col-md-6">
            <label for="country" class="form-label">Country</label>
            <select style="background-color: var(--input-color) !important" id="country" class="form-select" formControlName="country"
              required
              (change)="onCountryChange($event)">
              <option value *ngIf="countries.length > 1">Select Country</option>
              <option *ngFor="let country of countries" [value]="country.name">
                {{ country.name }}
              </option>
            </select>
            <div *ngIf="
                registrationForm!.get('country')?.invalid &&
                isSubmitted
              " class="text-danger">
              Please select your country.
            </div>
          </div>

          <div class="form-group col-md-6">
            <label for="address1" class="form-label">Address 1</label>
            <input style="background-color: var(--input-color) !important" type="text" class="form-control py-3" id="address1"
              formControlName="address1"
              placeholder="Address Line 1" required />
            <div *ngIf="
                registrationForm!.get('address1')?.invalid &&
                isSubmitted
              " class="text-danger">
              Please enter your address.
            </div>
            <div class="text-danger"
              *ngIf="registrationForm!.get('address1')?.errors?.['maxlength']">
              Address cannot exceed 25 characters.
            </div>
          </div>
        </div>

        <div class="form-row row">
          <div class="form-group col-md-6">
            <label for="address2" class="form-label">Address 2</label>
            <input style="background-color: var(--input-color) !important" type="text" class="form-control py-3" id="address2"
              formControlName="address2"
              placeholder="Address Line 2" />
            <div *ngIf="
                registrationForm!.get('address2')?.invalid &&
                isSubmitted
              " class="text-danger">
              Please enter your address.
            </div>
            <div class="text-danger"
              *ngIf="registrationForm!.get('address2')?.errors?.['maxlength']">
              Address cannot exceed 25 characters.
            </div>
          </div>
          <div class="form-group col-md-6">
            <label for="city" class="form-label">City</label>
            <div class="select-editable" class="form-group">
              <ng-select [items]="filteredCities" bindLabel="name"
                bindValue="name" labelForId="state"
                [multiple]="false" [placeholder]="placeholder"
                [loadingText]="'Loading Cities...'"
                [notFoundText]="'No cities found'" [loading]="loadingCities"
                formControlName="city"
                (change)="onCityChange($event)" [searchable]="true"
                (focus)="onFocus()" (blur)="onBlur()"
                (search)="onSearch($event)">
              </ng-select>
            </div>

            <div *ngIf="
                (registrationForm!.get('city')?.invalid &&
                  isSubmitted) ||
                cityError
              " class="text-danger">
              Please select your city.
            </div>
          </div>

        </div>

        <div class="form-row row">
          <div class="form-group col-md-6">
            <label for="postCode" class="form-label">Post Code</label>
            <input style="background-color: var(--input-color) !important" type="text" class="form-control py-3" id="postCode"
              formControlName="postCode"
              placeholder="Enter Post Code" required />
            <div *ngIf="
                registrationForm!.get('postCode')?.invalid &&
                isSubmitted
              " class="text-danger">
              Please enter your postal code.
            </div>
          </div>

        </div>
        <div class="form-row row mt-5">
          <div class="form-group col-12 col-md-6">
            <button type="button" class="btn btn-outline-primary" routerLink>
              Cancel
            </button>
          </div>
          <div class="form-group col-md-6">
            <button type="submit" [disabled]="enableRegister"
              class="btn btn-primary">Register Now</button>
          </div>
        </div>
      </form>
    </div>

    <div class="container form-container" *ngIf="megaMenu">
      <form [formGroup]="registrationForm!" (ngSubmit)="onSubmit()">
        <div class="form-row row">
          <div class="form-group col-md-3">
            <label for="firstName" class="form-label">First Name</label>
            <input style="background-color: var(--input-color) !important" type="text" class="form-control py-3" id="firstName"
              formControlName="firstName"
              placeholder="Enter First Name" required />
            <div *ngIf="
                registrationForm!.get('firstName')?.invalid &&
                isSubmitted
              " class="text-danger">
              Please enter your first name.
            </div>
          </div>

          <div class="form-group col-md-3">
            <label for="lastName" class="form-label">Last Name</label>
            <input style="background-color: var(--input-color) !important" type="text" class="form-control py-3" id="lastName"
              formControlName="lastName"
              placeholder="Enter Last Name" required />
            <div *ngIf="
                registrationForm!.get('lastName')?.invalid &&
                isSubmitted
              " class="text-danger">
              Please enter your last name.
            </div>
          </div>

          <div class="form-group col-md-6">
            <label for="email" class="form-label">Email</label>
            <input style="background-color: var(--input-color) !important" type="email" class="form-control py-3" id="email"
              formControlName="email"
              placeholder="Enter your email" required />
            <div *ngIf="
                registrationForm!.get('email')?.invalid &&
                isSubmitted
              " class="text-danger">
              Please enter a valid email.
            </div>
          </div>
        </div>
        <div class="form-row row">
          <div class="form-group col-md-6">
            <label for="phone" class="form-label">Contact Number</label>
            <input style="background-color: var(--input-color) !important" type="text" class="form-control py-3" id="phone"
              formControlName="phone" placeholder="+4467456543"
              required />
            <div *ngIf="
                registrationForm!.get('phone')?.invalid &&
                isSubmitted
              " class="text-danger">
              Please enter your contact number.
            </div>
          </div>
          <div class="form-group col-md-6">
            <label for="companyName" class="form-label">Company Name</label>
            <input style="background-color: var(--input-color) !important" type="text" class="form-control py-3" id="companyName"
              formControlName="companyName"
              placeholder="Enter Company Name" required [disabled]="true" />
            <div *ngIf="
                registrationForm!.get('companyName')?.invalid &&
                isSubmitted
              " class="text-danger">
              Please enter your company name.
            </div>
          </div>
        </div>
        <div class="form-row row">
          <div class="form-group col-md-6">
            <label for="job" class="form-label">Job Title</label>
            <div class="select-editable" class="form-group">
              <ng-select [items]="jobTitles" bindLabel="title" bindValue="title"
                labelForId="job" [multiple]="false"
                [placeholder]="jobPlaceHolder" formControlName="jobTitle"
                (change)="onJobChange($event)"
                [searchable]="true" (focus)="onFocus()" (blur)="onBlur()"
                (search)="onJobSearch($event)">
              </ng-select>
            </div>

            <div *ngIf="
                (registrationForm!.get('city')?.invalid &&
                  isSubmitted) ||
                cityError
              " class="text-danger">
              Please select your city.
            </div>
          </div>

          <div class="form-group col-md-6">
            <label for="country" class="form-label">Country</label>
            <div class="select-editable" class="form-group">
              <ng-select [items]="countries" bindLabel="name" bindValue="name"
                labelForId="country" [multiple]="false"
                [placeholder]="countryPlaceholder" formControlName="country"
                (change)="onCountryChange($event)"
                [searchable]="true" (focus)="onFocus()" (blur)="onBlur()"
                (search)="onCountrySearch($event)">
              </ng-select>
            </div>

            <!-- <select style="background-color: var(--input-color) !important" id="country" class="form-select" formControlName="country"
              required (change)="onCountryChange($event)">
              <option value *ngIf="countries.length>1">Select Country</option>
              <option *ngFor="let country of countries"
                [value]="country.name">{{ country.name }}</option>
            </select> -->
            <div *ngIf="
                registrationForm!.get('country')?.invalid &&
                isSubmitted
              " class="text-danger">
              Please select your country.
            </div>
          </div>
        </div>

        <div class="form-row row">
          <div class="form-group col-md-6">
            <label for="address1" class="form-label">Address 1</label>
            <input style="background-color: var(--input-color) !important" type="text" class="form-control py-3" id="address1"
              formControlName="address1"
              placeholder="Address Line 1" required />
            <div *ngIf="
                registrationForm!.get('address1')?.invalid &&
                isSubmitted
              " class="text-danger">
              Please enter your address.
            </div>
            <div class="text-danger"
              *ngIf="registrationForm!.get('address1')?.errors?.['maxlength']">
              Address cannot exceed 25 characters.
            </div>
          </div>

          <div class="form-group col-md-6">
            <label for="address2" class="form-label">Address 2</label>
            <input style="background-color: var(--input-color) !important" type="text" class="form-control py-3" id="address2"
              formControlName="address2"
              placeholder="Address Line 2" />
            <div *ngIf="
                registrationForm!.get('address2')?.invalid &&
                isSubmitted
              " class="text-danger">
              Please enter your address.
            </div>
            <div class="text-danger"
              *ngIf="registrationForm!.get('address2')?.errors?.['maxlength']">
              Address cannot exceed 25 characters.
            </div>
          </div>
        </div>

        <div class="form-row row">
          <div class="form-group col-md-6">
            <label for="city" class="form-label">City</label>
            <div class="select-editable" class="form-group">
              <!-- <select style="background-color: var(--input-color) !important"
              id="city"
              class="form-select"
              formControlName="city"
              (input)="onCityInput($event)"
              (change)="onCityChange($event)">
              <option *ngIf="registrationForm!.get('country')?.value == ''"
                value>Select Country First</option>
              <option *ngIf="loadingCities" value>Loading Cities...</option>
              <option *ngIf="!loadingCities && filteredCities.length > 0"
                value>Select City</option>
              <option *ngFor="let city of filteredCities"
                [value]="city.code">{{ city.name }}</option>
            </select> -->

              <ng-select [items]="filteredCities" bindLabel="name"
                bindValue="name" labelForId="state"
                [multiple]="false" [placeholder]="placeholder"
                [loadingText]="'Loading Cities...'"
                [notFoundText]="'No cities found'" [loading]="loadingCities"
                formControlName="city"
                (change)="onCityChange($event)" [searchable]="true"
                (focus)="onFocus()" (blur)="onBlur()"
                (search)="onSearch($event)">
              </ng-select>
            </div>

            <div *ngIf="
                (registrationForm!.get('city')?.invalid &&
                  isSubmitted) ||
                cityError
              " class="text-danger">
              Please select your city.
            </div>
          </div>

          <div class="form-group col-md-6">
            <label for="postCode" class="form-label">Post Code</label>
            <input style="background-color: var(--input-color) !important" type="text" class="form-control py-3" id="postCode"
              formControlName="postCode"
              placeholder="Enter Post Code" required />
            <div *ngIf="
                registrationForm!.get('postCode')?.invalid &&
                isSubmitted
              " class="text-danger">
              Please enter your postal code.
            </div>
          </div>
        </div>
        <!-- <div class="form-row row">
        <div class="form-group col-12">
          <label for="input10">State:</label>
          <input style="background-color: var(--input-color) !important" type="text" class="form-control" id="input10" formControlName="state"
            placeholder="Provide State/Province" />
        </div>
      </div> -->

        <div class="form-row row">
          <div class="form-group col-md-6">
            <label for="orgType" class="form-label">Organisation Type </label>
            <select style="background-color: var(--input-color) !important" id="orgType" class="form-select" formControlName="orgType"
              required
              (change)="onOrgTypeChange($event)">
              <option *ngFor="let type of orgType" [value]="type">
                {{ type }}
              </option>
            </select>

          </div>

          <div class=" form-group col-md-6">
            <label for="shipments" class="form-label">Total number of annual
              shipments
            </label>

            <select style="background-color: var(--input-color) !important" aria-label="Select annual shipments" class="form-select"
              formControlName="shipments">

              <option value="New to shipping">New to shipping</option>
              <option value="Less than 10">Less than 10</option>
              <option value="10+">10+</option>
              <option value="100+">100+</option>
              <option value="300+">300+</option>
              <option value="500+">500+</option>
            </select>
          </div>

        </div>
        <div class="form-row row">
          <div class="form-group col-md-6 d-flex align-items-end"
            *ngIf="isForwarder">

            <div class="form-group w-100 col-md-6 d-flex align-items-end"
              *ngIf="isForwarder">
              <div
                class="d-flex flex-row mt-5 w-100 justify-content-evenly align-items-center bg-white rounded-2 heightClass"
                style="padding:0.45rem !important; background-color: var(--input-color) !important ">

                <!-- WCA Member -->
                <div
                  class="d-flex flex-row justify-content-between align-items-center">
                  <input   id="WCA Member" type="checkbox" name="agencyName"
                    class="me-3 form-check-input w-auto rounded-5"
                    [checked]="registrationForm!.get('agencyName')?.value === 'WCA'"
                    (change)="toggleSelection('WCA')">
                  <label for="WCA Member" class="fs-6 "
                    [class.fw-semibold]="registrationForm!.get('agencyName')?.value === 'WCA'">
                    WCA Member
                  </label>
                </div>

                <!-- JCTRANS Member -->
                <div
                  class="d-flex flex-row justify-content-between align-items-center">
                  <input   id="JCTRANS Member" type="checkbox" name="agencyName"
                    class="me-3 form-check-input w-auto rounded-5"
                    [checked]="registrationForm!.get('agencyName')?.value === 'JCTRANS'"
                    (change)="toggleSelection('JCTRANS')">
                  <label for="JCTRANS Member" class="fs-6 "
                    [class.fw-semibold]="registrationForm!.get('agencyName')?.value === 'JCTRANS'">
                    JCTRANS Member
                  </label>
                </div>

              </div>
            </div>

          </div>
          <div class="form-group col-md-6" *ngIf="registerationField">
            <label for="registerationNumber" class="form-label">Enter your
              {{
              registrationForm!.get('agencyName')?.value }}
              registeration number:</label>
            <input style="background-color: var(--input-color) !important" type="text" class="form-control py-3"
              id="registerationNumber" formControlName="registerationNumber"
              placeholder="Enter registeration number" />
            <div *ngIf="
                registrationForm!.get('registerationNumber')?.invalid &&
                isSubmitted
              " class="text-danger">
              Please enter your registration number.
            </div>
          </div>
        </div>

        <div class="form-row row mt-5">
          <div class="form-group col-12 col-md-6">
            <button type="button" class="btn btn-outline-primary" routerLink>
              Cancel
            </button>
          </div>
          <div class="form-group col-md-6">
            <button type="submit" class="btn btn-primary">Register
              Now</button>
          </div>

        </div>
      </form>
    </div>
  </div>
</div>