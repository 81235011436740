import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GetNewsService } from '../../../services/get-news.service';

import { Meta, Title } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.css', '../../../shared/style.css'],
})
export class BlogDetailsComponent {
  article: any;
  articleUrl: string | undefined;
  formattedPublishedAt: string | undefined;
  description: any = [];
  articleId: any;
  currentUrl: string | undefined;
  shareUrl: string | undefined;
  loader: boolean = true;

  metaImg: any;

  metaTitle: any;

  constructor(private router: Router, private route: ActivatedRoute, private newsService: GetNewsService, private meta: Meta, private title: Title, @Inject(PLATFORM_ID) private platformId: Object) {

    console.log('BlogDetailsComponent constructor');
    if (isPlatformBrowser(this.platformId)) {
      this.route.queryParams.subscribe(params => {


        this.blogId = params['id'];
        this.metaImg = params['image'];
        this.metaTitle = params['title'];

        this.setMetaTags();

        if (this.blogId) {
          this.fetchArticleById(this.blogId);

        }
      });
    }
  }
  rightImageInfo = {
    heading: ` Why Ocean Freight Rates are Surging: A Look at the Supply
    Shock after the Suez Canal Blockage.`,
    description: `Stay informed with real-time news, expert analysis, and trends.`,
    img: '../../../assets/imgs/AirFreight.png',
    btnTxt1: '',
    btnLink1: '',
    btnTxt2: '',
    btnLink2: '',
    class: 'bg-light',
  };

  blogId: any;
  ngOnInit(): void {
    console.log('BlogDetailsComponent INIT');
    // this.meta.removeTag('property="og:title"');
    // this.meta.removeTag('property="og:description"');
    // this.meta.removeTag('property="og:image"');

    if (isPlatformBrowser(this.platformId)) {


      // // this.blogId = this.route.snapshot.paramMap.get('id');
      // this.route.queryParams.subscribe(params => {


      //   this.blogId = params['id'];
      //   if (this.blogId) {
      //     this.fetchArticleById(this.blogId);

      //   }
      // });

      // this.title.setTitle("Intercargo News - Intercargo Logistics ");

      // this.meta.removeTag('name="author"');
      // this.meta.removeTag('property="og:title"');
      // this.meta.removeTag('property="og:description"');
      // this.meta.removeTag('property="og:image"');
      // this.meta.addTags([

      //   { name: 'author', content: 'Intercargo Logistics' },
      //   { property: 'og:title', content: 'Intercargo News' },
      //   {
      //     property: 'og:description', content: `${this.article?.title ?? 'Daily Cargo News'}`
      //   },
      //   {
      //     property: 'og:image', content: `${this.article?.image}`
      //   },
      //   // { property: 'og:image', content: 'https://www.intercargo.com/assets/imgs/AirRight1.png' }
      // ]);
      this.currentUrl = window.location.href;
    }

  }

  fetchArticleById(id: string): void {
    // Call the API to get the article by ID
    this.newsService.getSingleNews(id).subscribe((response) => {
      if (response) {
        this.article = response.articles.results[0];

        this.articleUrl = this.article.url;
        this.loader = false;
        this.setMetaTags();
      }
      this.currentUrl = this.router.url;

      this.articleId = this.article.uri;

      if (this.article) {
        this.formatPublishedAt();
        this.divideIntoParagraphs(this.article.body, 5);

      } else {
      }
    });
  }



  setMetaTags() {
    this.meta.removeTag('property="og:title"');
    this.meta.removeTag('property="og:description"');
    this.meta.removeTag('property="og:image"');

    this.meta.addTags([
      {
        property: 'og:description', content: 'Intercargo News'
      }, { property: 'og:title', content: `${this.metaTitle}` },

      { property: 'og:type', content: 'website' },
      {
        property: 'og:image', content: `${this.metaImg}`
      },



      {
        property: 'twitter:description', content: 'Intercargo News'
      },
      { property: 'twitter:title', content: `${this.metaTitle}` },

      { property: 'og:type', content: 'website' },
      {
        property: 'twitter:image', content: `${this.metaImg}`
      }
    ]);
  }


  // setTitleAndMetaTags(urlToShare: any): void {

  //   this.title.setTitle(this.article.title);
  //   this.meta.removeTag('property="og:title"');

  //   this.meta.removeTag('property="og:description"');
  //   this.meta.removeTag('property="og:image"');

  //   this.meta.addTags([
  //     {
  //       property: 'og:description', content: 'Intercargo News'
  //     }, { property: 'og:title', content: `${this.article?.title ?? 'Daily Cargo News'}` },

  //     { property: 'og:url', content: urlToShare },
  //     { property: 'og:type', content: 'website' },
  //     {
  //       property: 'og:image', content: `${this.article?.image}`
  //     },

  //     // Meta tag for twitter

  //     {
  //       property: 'twitter:description', content: 'Intercargo News'
  //     },
  //     { property: 'twitter:title', content: `${this.article?.title ?? 'Daily Cargo News'}` },

  //     { property: 'twitter:url', content: urlToShare },
  //     { property: 'og:type', content: 'website' },
  //     {
  //       property: 'twitter:image', content: `${this.article?.image}`
  //     }
  //   ]);
  // }




  divideIntoParagraphs(text: string, linesPerParagraph: number) {
    const lines = text.split('\n'); // Split by lines assuming \n denotes a line break
    const paragraphs: string[] = [];
    let currentParagraph = '';

    for (let i = 0; i < lines.length; i++) {
      if (currentParagraph.split('\n').length >= linesPerParagraph) {
        paragraphs.push(currentParagraph);
        currentParagraph = '';
      }
      currentParagraph += lines[i] + '\n';
    }

    // Push the last remaining paragraph
    if (currentParagraph.trim().length > 0) {
      paragraphs.push(currentParagraph.trim());
    }

    this.description = paragraphs;
  }


  formatPublishedAt() {
    if (this.article && this.article.publishedAt) {
      this.formattedPublishedAt = this.article.publishedAt.slice(0, 10);
    }
  }

  share(platform: string): void {
    this.currentUrl = window.location.href;
    const urlToShare = `${this.currentUrl}`; // Replace with your actual URL to share
    console.log(urlToShare);
    // this.setTitleAndMetaTags(urlToShare);
    switch (platform) {
      case 'linkedin':
        this.shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(urlToShare)}`;
        break;
      case 'twitter':

        this.shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(urlToShare)}`;
        break;
      case 'facebook':

        this.shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(urlToShare)}`;
        break;
      default:
        console.warn(`Unsupported platform: ${platform}`);
        return;
    }

    // Open the share URL in a new tab
    window.open(this.shareUrl, '_blank');
  }


  navigateToBlog() {
    this.router.navigate(['/blog']);

  }
}
