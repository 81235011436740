import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CityService } from '../../../services/city.service';
import { OrganizationService } from '../../../services/organization.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SubscribePopUpComponent } from '../../../shared/components/subscribe-pop-up/subscribe-pop-up.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ThankyouComponent } from '../../../components/thankyou/thankyou.component';
import { ActivatedRoute, Router } from '@angular/router';
import { RoutetracingService } from '../../../services/routetracing.service';
import { Meta, Title } from '@angular/platform-browser';
import e from 'express';
import { DarkModeService } from '../../../services/dark-mode.service';
import { Subscription } from 'rxjs';
import { jobTitles } from './data';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-openaccount',
  templateUrl: './openaccount.component.html',
  styleUrl: './openaccount.component.css'
})
export class OpenaccountComponent {

  registrationForm: FormGroup | undefined;

  jobTitles = jobTitles;
  formDataToSend = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    customerID: '',
    country: '',
    companyName: '',
    jobTitle: '',
    agencyName: '',
    orgType: '',
    city: '',
    postCode: '',
    address1: '',
    address2: '',
    registerationNumber: '',
    isFreightForwarder: false,
    shipments: 'New to shipping'
  };

  // orgType = [
  //   'Consignor', 'Consignee', 'Forwarder/Agent', 'Carrier', 'Global Supplier'
  // ]
  orgType = [
    'Consignor/Shipper/Supplier/Seller', 'Consignee/Receiver/Buyer', 'Forwarder/Agent', 'Carrier', 'Global Supplier'
  ]
  countries = this.cityService.getCountries();

  cities: any[] = [];
  public isSubmitted: boolean = false;

  // email: string | undefined;
  subscription: Subscription | null = null;
  cityCountry: boolean = false;
  loader: boolean = false;
  validcustomerID: boolean | undefined;
  loadingCities: boolean = false;
  paramsReceived: any;
  dialogRef: MatDialogRef<SubscribePopUpComponent> | null;
  megaMenu: boolean = false;
  enableRegister = true;
  constructor(private router: Router, public dialog: MatDialog, private formBuilder: FormBuilder, private http: HttpClient, private cityService: CityService, private organizationService: OrganizationService, private snackBar: MatSnackBar, private routeTracing: RoutetracingService, private activatedRoute: ActivatedRoute, private title: Title, private meta: Meta, private darkModeService: DarkModeService, @Inject(PLATFORM_ID) private platformId: Object) {
    this.dialogRef = null;
  }
  conexaLogo = '../../../../assets/logo/Conexa-blue-green.png';

  disableAllExceptCustomerID() {
    Object.keys(this.registrationForm!.controls).forEach((controlName) => {
      if (controlName !== 'customerID') {
        this.registrationForm!.get(controlName)?.disable();
      } else {
        this.registrationForm!.get(controlName)?.enable();  // Ensure customerID remains enabled
      }
    });
  }
  enableAllExceptCustomerID() {
    Object.keys(this.registrationForm!.controls).forEach((controlName) => {

      this.registrationForm!.get(controlName)?.enable();  // Ensure customerID remains enabled

    });
  }
  ngOnInit(): void {

    this.subscription = this.darkModeService.darkMode$.subscribe(isDarkMode => {
      if (isDarkMode) {
        this.conexaLogo = '../../../../assets/logo/Conexa-white.png';
      } else {
        this.conexaLogo = '../../../../assets/logo/Conexa-blue-green.png';
      }
    })


    this.registrationForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: [''],
      customerID: [''],
      country: [''],
      companyName: [''],
      jobTitle: [''],
      orgType: [this.orgType[0]],
      city: [''],
      postCode: [''],
      address1: ['', [Validators.maxLength(25)]],
      address2: ['', [Validators.maxLength(25)]],
      isFreightForwarder: [false],
      agencyName: [''],
      registerationNumber: [''],
      shipments: ['New to shipping'],
    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.paramsReceived = params;
      if (this.paramsReceived.path === 'megaMenu') {
        this.megaMenu = true;
        this.enableAllExceptCustomerID()
      } else if (this.paramsReceived.path === 'conexa') {
        this.megaMenu = false;
        this.disableAllExceptCustomerID();
      }
    })

    function toCapitalize(str: any): string {
      if (!str) return '';
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
    this.title.setTitle(toCapitalize("Open An Account") + " - Intercargo Logistics");

    this.meta.removeTag('name="author"');
    this.meta.removeTag('property="og:title"');
    this.meta.removeTag('property="og:description"');
    // this.meta.removeTag('property="og:image"');
    this.meta.addTags([
      { name: 'keywords', content: 'Logistics, cargo, air freight, sea freight' },
      { name: 'author', content: 'Intercargo Logistics' },
      { property: 'og:title', content: 'Open An Account' },
      {
        property: 'og:description', content: "Get ready to streamline your logistics with Conexa: Expertly delivered."
      },
      // { property: 'og:image', content: 'https://www.intercargo.com/assets/imgs/AirRight1.png' }
    ]);

  }

  registerationField: boolean = false;

  toggleSelection(value: string) {
    const currentValue = this.registrationForm!.get('agencyName')?.value;
    if (currentValue === value) {
      // Unselect the current value
      this.registrationForm!.patchValue({ agencyName: null });
      this.registerationField = false;
    } else {
      // Select the clicked value
      this.registrationForm!.patchValue({ agencyName: value });

      this.registerationField = true;

    }
  }

  onCustomerIDFocusOut(): void {

    if (this.registrationForm!.get('customerID')!.value != '') {
      // Disable form fields if customerID is not an empty string
      this.registrationForm!.get('country')!.disable();
      this.registrationForm!.get('companyName')!.disable();
      this.registrationForm!.get('city')!.disable();
      this.registrationForm!.get('postCode')!.disable();
      this.registrationForm!.get('address1')!.disable();
      this.registrationForm!.get('address2')!.disable();
      this.registrationForm!.get('isFreightForwarder')!.disable();
      this.makeApiCall()
    } else {
      // Enable form fields if customerID is an empty string
      this.disableAllExceptCustomerID();
    }
  }

  dataReceived: any;
  makeApiCall(): void {
    this.loader = true;

    this.organizationService.getOrganizationDetails(this.registrationForm!.get('customerID')!.value).subscribe(
      (response) => {
        const data = response.data.native.body.organization[0].orgHeader;
        this.dataReceived = data;
        const cityValue = data.orgAddressCollection.orgAddress.city;
        if (this.dataReceived) {
          this.validcustomerID = true
        }
        this.placeholder = data.orgAddressCollection.orgAddress.city;
        let countryValue = data.orgAddressCollection.orgAddress.countryCode.code;
        this.cities = []
        if (cityValue && countryValue) {
          this.cityCountry = true;
          countryValue = this.countries.find(c => c.code === countryValue)?.name;
          this.registrationForm!.get('address1')!.setValue(data.orgAddressCollection.orgAddress.address1);
          this.registrationForm!.get('address2')!.setValue(data.orgAddressCollection.orgAddress.address2);
          this.registrationForm!.get('postCode')!.setValue(data.orgAddressCollection.orgAddress.postCode);
          // this.registrationForm!.get('city')!.setValue(cityValue);
          // this.cities.push(cityValue);
          this.registrationForm!.get('country')!.setValue(countryValue);
          this.registrationForm!.get('companyName')!.setValue(data.fullName);
          this.registrationForm!.get('isFreightForwarder')!.setValue((data.isForwarder === 'true'));

          this.registrationForm!.get('firstName')!.enable();
          this.registrationForm!.get('lastName')!.enable();
          this.registrationForm!.get('email')!.enable();
          this.registrationForm!.get('phone')!.enable();
          this.registrationForm!.get('jobTitle')!.enable();

          this.formDataToSend = {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            customerID: this.registrationForm!.get('customerID')!.value || '',
            country: this.registrationForm!.get('country')!.value || '',
            companyName: this.registrationForm!.get('companyName')!.value || '',
            jobTitle: this.registrationForm!.get('jobTitle')!.value || '',
            orgType: this.registrationForm!.get('orgType')!.value || '',
            city: this.registrationForm!.get('city')!.value || '',
            postCode: this.registrationForm!.get('postCode')!.value || '',
            address1: this.registrationForm!.get('address1')!.value || '',
            address2: this.registrationForm!.get('address2')!.value || '',
            registerationNumber: this.registrationForm!.get('registerationNumber')!.value || '',
            agencyName: this.registrationForm!.get('agencyName')!.value || '',
            isFreightForwarder: (this.registrationForm!.get('isFreightForwarder')!.value === 'true'),
            shipments: this.registrationForm!.get('shipments')!.value || ''
          };
        }

        // this.disableSetFields();
        // this.enableRemainingFields();

        this.loader = false;
        this.enableRegister = false;

      },
      (error) => {

        this.validcustomerID = false
        this.loader = false;
        this.router.navigate([{ outlets: { modal: ['downloadthankyou'] } }], {
          queryParams: { title: 'errorMessage' }
        });
        this.disableAllExceptCustomerID();
        this.enableRegister = true;
      }
    );
  }

  agencyName = "WCA";

  placeholder = 'Select City';
  countryPlaceholder = 'Select Country';
  orgTypeSelected: any;
  isForwarder: boolean = false;
  onSelectionChange(event: string) {
    // You can handle the selected value here, for example, making API calls or updating the UI
  }

  orgTypes = {
    'IsConsignee': 'false',
    'IsConsignor': 'true',
    'IsForwarder': 'false',
    'IsShippingProvider': 'false',
    'IsGlobalAccount': 'false'
  }
  onOrgTypeChange(event: any) {
    const selectElement = event.target as HTMLSelectElement;
    const selectedValue = selectElement.value;
    this.orgTypeSelected = selectedValue;

    this.registrationForm!.get('agencyName')?.patchValue('');
    switch (selectedValue) {
      case 'Consignor/Shipper/Supplier/Seller':
        this.orgTypes.IsConsignor = 'true';
        this.orgTypes.IsConsignee = 'false';
        this.orgTypes.IsForwarder = 'false';
        this.orgTypes.IsShippingProvider = 'false';
        this.orgTypes.IsGlobalAccount = 'false';
        break;
      case 'Consignee/Receiver/Buyer':
        this.orgTypes.IsConsignor = 'false';
        this.orgTypes.IsConsignee = 'true';
        this.orgTypes.IsForwarder = 'false';
        this.orgTypes.IsShippingProvider = 'false';
        this.orgTypes.IsGlobalAccount = 'false';
        break;
      case 'Forwarder/Agent':
        this.orgTypes.IsConsignor = 'false';
        this.orgTypes.IsConsignee = 'false';
        this.orgTypes.IsForwarder = 'true';
        this.orgTypes.IsShippingProvider = 'false';
        this.orgTypes.IsGlobalAccount = 'false';
        break;
      case 'Carrier':
        this.orgTypes.IsConsignor = 'false';
        this.orgTypes.IsConsignee = 'false';
        this.orgTypes.IsForwarder = 'false';
        this.orgTypes.IsShippingProvider = 'true';
        this.orgTypes.IsGlobalAccount = 'false';
        break;
      case 'Global Supplier':
        this.orgTypes.IsConsignor = 'false';
        this.orgTypes.IsConsignee = 'false';
        this.orgTypes.IsForwarder = 'false';
        this.orgTypes.IsShippingProvider = 'false';
        this.orgTypes.IsGlobalAccount = 'true';
        break;
    }

    if (selectedValue === 'Forwarder/Agent') {
      this.isForwarder = true;


    } else {
      this.isForwarder = false;
      this.registerationField = false;
    }
  }

  onCountryChange(event: any) {

    this.cities = [];
    this.registrationForm!.get('city')!.setValue('');

    if (event.code.length > 0 || this.selectedCountry.length > 0) {
      this.countryPlaceholder = '';
    } else {
      this.countryPlaceholder = 'Select Country';
    }
    if (this.registrationForm!.get('customerID')!.value == '') {
      // if (country) {
      this.loadingCities = true;
      this.registrationForm!.get('city')!.disable();

      this.placeholder = 'Select City';

      const selectedValue = event.name;
      this.fetchCities(selectedValue);
    }
  }

  fetchCities(country: string): void {
    const countries = this.cityService.getCountries();
    const countrycode = countries.find(c => c.name === country)?.code;
    this.cityService.getCities(countrycode).subscribe(
      (response) => {
        this.cities = response; // Adjust according to API response structure
        this.filteredCities = this.cities;
        this.cities.unshift({ name: 'Select City', code: '', timezone: '' });
        this.placeholder = '';
        this.registrationForm!.get('city')?.setValue(this.filteredCities[0].name);

        this.loadingCities = false;
        this.registrationForm!.get('city')!.enable();
      },
      (error) => {
      }
    );
  }
  cityError: boolean = false;

  onSubmit(): void {
    this.isSubmitted = true;
    if (this.placeholder.includes('Select City')) {

      this.cityError = true;
      return;
    }
    if (this.registrationForm!.invalid) {

      this.registrationForm!.markAllAsTouched();
      return;
    }
    if (this.registrationForm!.valid) {
      this.cityError = false;
      const formData = this.registrationForm!.value;

      if (formData.customerID === '') {
        this.formDataToSend.customerID = '';
        this.formDataToSend.firstName = formData.firstName;
        this.formDataToSend.lastName = formData.lastName;
        this.formDataToSend.email = formData.email;
        this.formDataToSend.phone = formData.phone;
        this.formDataToSend.address1 = formData.address1;
        this.formDataToSend.address2 = formData.address2;
        this.formDataToSend.postCode = formData.postCode;
        this.formDataToSend.city = formData.city;
        var countrycode = this.countries.find(c => c.name === formData.country)?.code;
        this.formDataToSend.country = countrycode ?? '';
        this.formDataToSend.companyName = formData.companyName;
        this.formDataToSend.jobTitle = formData.jobTitle;
        this.formDataToSend.agencyName = formData.agencyName;
        this.formDataToSend.orgType = formData.orgType;
        this.formDataToSend.registerationNumber = formData.registerationNumber;
        this.formDataToSend.isFreightForwarder = formData.isFreightForwarder.toString();
        this.formDataToSend.shipments = formData.shipments;
      }
      else {
        this.formDataToSend.firstName = formData.firstName;
        this.formDataToSend.lastName = formData.lastName;
        this.formDataToSend.email = formData.email;
        this.formDataToSend.phone = formData.phone;
        this.formDataToSend.customerID = formData.customerID;
        const countryValue = this.registrationForm!.get('country')?.value ?? 'defaultCountry';
        this.formDataToSend.city = this.dataReceived?.orgAddressCollection?.orgAddress?.city ?? this.selectedCity.code;
        var countrycode = this.countries.find(c => c.name === countryValue)?.code;
        this.formDataToSend.country = countrycode ?? '';
      }
      let apiData: any;
      if (this.megaMenu) {
        let OrgDebtorGroup = null;
        if (this.orgTypeSelected === 'Forwarder/Agent') {


          OrgDebtorGroup = {
            Code: this.formDataToSend.agencyName,
            ARExternalDebtorCode: `${this.formDataToSend.agencyName} ID ${this.formDataToSend.registerationNumber}`
          }
        }
        apiData = {
          Orgcode: this.formDataToSend.customerID,
          orgName: this.formDataToSend.companyName,
          jobTitle: this.selectedJob,
          firstName: this.formDataToSend.firstName,
          lastName: this.formDataToSend.lastName,
          phone: this.formDataToSend.phone,
          email: this.formDataToSend.email,
          address1: this.formDataToSend.address1,
          address2: this.formDataToSend.address2,
          postCode: this.formDataToSend.postCode,
          city: this.formDataToSend.city,
          countrycode: this.formDataToSend.country,
          isForwarder: this.formDataToSend.isFreightForwarder.toString(),
          unloco: this.selectedCity.code,
          orgType: this.orgTypes,
          shipments: this.formDataToSend.shipments,
          OrgDebtorGroup: OrgDebtorGroup,
          page: "company-registration",
          eventCode: "NCA"
        };

      }
      else {
        apiData = {
          Orgcode: this.formDataToSend.customerID,
          orgName: this.formDataToSend.companyName,
          jobTitle: this.selectedJob,
          firstName: this.formDataToSend.firstName,
          lastName: this.formDataToSend.lastName,
          phone: this.formDataToSend.phone,
          email: this.formDataToSend.email,
          address1: this.formDataToSend.address1,
          address2: this.formDataToSend.address2,
          postCode: this.formDataToSend.postCode,
          city: this.formDataToSend.city,
          countrycode: this.formDataToSend.country,
          isForwarder: this.formDataToSend.isFreightForwarder.toString(),
          unloco: this.dataReceived?.closestPort?.code ?? this.selectedCity.code,
          page: 'conexa-signup',
          eventCode: "CR"
        };
      }



      this.loader = true;

      if (this.megaMenu) {

        this.organizationService.submitOrganizationData(apiData).subscribe(
          (response) => {
            this.loader = false;
            this.routeTracing.setParamValue(this.paramsReceived)
            if (response.message.includes('Contact with same email address already Exist')) {
              this.snackBar.open('Contact with same Email Address already exists and Oppurtunity has been updated', 'Success', {
                duration: 8000,
                horizontalPosition: 'end',
                verticalPosition: 'top',
                panelClass: ['snackbar-success'],
              });
            }
            this.router.navigate([{ outlets: { modal: ['downloadthankyou'] } }], {
              queryParams: { path: 'megaMenu' }
            });
          },
          (err) => {
            this.loader = false;
            this.snackBar.open(err.error.message, err.error.status, {
              duration: 5000,
              horizontalPosition: 'end',
              verticalPosition: 'top',
              panelClass: ['snackbar-error'],
            });
          }
        );
      }
      else {
        this.organizationService.submitOrganizationDataConexa(apiData).subscribe(
          (response) => {
            this.loader = false;
            this.routeTracing.setParamValue(this.paramsReceived)
            if (response.message.includes('Contact with same email address already Exist')) {
              this.snackBar.open('Contact with same Email Address already exists and Oppurtunity has been updated', 'Success', {
                duration: 8000,
                horizontalPosition: 'end',
                verticalPosition: 'top',
                panelClass: ['snackbar-success'],
              });
            }
            this.router.navigate([{ outlets: { modal: ['downloadthankyou'] } }], {
              queryParams: { path: 'conexa' }
            });
          },
          (err) => {
            this.loader = false;
            this.snackBar.open(err.error.message, err.error.status, {
              duration: 5000,
              horizontalPosition: 'end',
              verticalPosition: 'top',
              panelClass: ['snackbar-error'],
            });
          }
        );
      }

    }
  }

  onCancel(): void {
    // Handle cancel action if needed
  }

  filteredCities: any[] = [];

  onCityInput(event: any): void {
    const inputValue = event.target.value.toLowerCase();
    // Filter cities based on input
    this.filteredCities = this.cities.filter(city =>
      city.name.toLowerCase().includes(inputValue)
    );

    // Update the form control value to reflect the input
    this.registrationForm!.get('city')?.setValue(inputValue);
  }

  isPlaceholderVisible = true; // Track placeholder visibility



  onSearch(term: any) {
    // When the user starts typing, hide the placeholder
    if (term.term.length > 0) {
      this.placeholder = '';
    } else {
      this.placeholder = 'Select City';
    }
    this.togglePlaceholder();
  }

  onJobSearch(term: any) {
    // When the user starts typing, hide the placeholder


    if (term.term.length > 0 || this.selectedJob.length > 0) {
      this.jobPlaceHolder = '';
    } else {
      this.jobPlaceHolder = 'Select Job';
    }
    this.togglePlaceholder();
  }

  selectedCountry = '';

  onCountrySearch(term: any) {


    if (term.term.length > 0 || this.selectedJob.length > 0) {
      this.countryPlaceholder = '';
    } else {
      this.countryPlaceholder = 'Select Country';
    }
    this.togglePlaceholder();
  }

  onFocus() {
    this.togglePlaceholder();
  }

  onBlur() {
    this.togglePlaceholder();
  }

  togglePlaceholder() {
    // Use the CSS class to hide/show the placeholder dynamically
    if (isPlatformBrowser(this.platformId)) {
      const ngSelectElem = document.querySelector('.ng-select');
      if (ngSelectElem) {
        if (this.isPlaceholderVisible) {
          ngSelectElem.classList.remove('ng-placeholder-hidden');
        } else {
          ngSelectElem.classList.add('ng-placeholder-hidden');
        }
      }
    }
  }


  selectedCity: any;

  onCityChange(event: any): void {
    // Get the selected city by matching the 'code' property
    this.selectedCity = this.cities.find(city => city === event);


    // Placeholder logic - hide placeholder when the city is selected
    if (event !== 'Select City' || event !== '') {
      this.placeholder = '';

    } else {
      this.placeholder = 'Select City';
    }

    // Update the form control with the selected city
    if (this.selectedCity) {
      this.registrationForm!.get('city')?.setValue(this.selectedCity.name);
    }
  }

  jobPlaceHolder = 'Select Title';
  selectedJob: any;


  onJobChange(event: any): void {
    this.selectedJob = event.title;
    if (event !== 'Select Title' || event !== '') {
      this.jobPlaceHolder = '';

    } else {
      this.jobPlaceHolder = 'Select Title';
    }

    if (this.selectedCity) {
      this.registrationForm!.get('city')?.setValue(this.selectedCity.name);
    }
  }


  openModal() {
    const dialogRef = this.dialog.open(ThankyouComponent, {
      width: '800px',
      maxHeight: '500px',
      // height: '95%',
      // panelClass: 'email-container',
      data: {
        btnTxt: 'Subscribe',
        heading: 'Stay Ahead of Intelligent Supply Chain with Our Newsletter',
        imgUrl: '../../../../assets/imgs/Subscribe.png',
        thanksTxt: 'You have successfully subscribed to our newsletter. Welcome to Intercargo community! Expect regular updates, exclusive content, and the latest news straight to your inbox. ',
        thanksHeading: `Thank You for <span class='text-success'> Subscribing! 🎉</span>`,
        email: false,

      },
      disableClose: true, // Optional: prevents closing the dialog by clicking outside or pressing ESC
    });
    dialogRef.afterClosed().subscribe((data: any) => {
      this.router.navigate([''])
    });
  }

}
interface OrganizationResponse {
  native: {
    body: {
      organization: Array<{
        orgHeader: any; // Define the correct type based on your API response
      }>;
    };
  };
}

interface ApiResponse {
  data: OrganizationResponse;
}
